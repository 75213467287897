import { createApp } from 'vue';
import mapboxgl from 'mapbox-gl';
import PerfectScrollbar from 'vue3-perfect-scrollbar';

import router from '@/router';
import store from '@/store';
import axios from '@/plugins/axios';
import mitt from '@/plugins/mitt';
import middleware from '@/plugins/middleware';

import App from './App.vue';


import { languages } from './i18n'
import { defaultLocale } from './i18n'
//import  {createI18n, useI18n} from 'vue-i18n'

import  {createI18n} from 'vue-i18n'

import components from './components';

import {
  Row,
  Col,
  Space,
  Badge,
  Layout,
  Typography,
  Radio,
  Checkbox,
  Avatar,
  Button,
  Input,
  Select,
  Dropdown,
  Menu,
  Table,
  Statistic,
  Tooltip,
  Spin,
  Form,
  Tabs,
  Skeleton,
  Switch,
  Collapse,
  Slider,
  Modal,
  DatePicker,
  InputNumber,
} from 'ant-design-vue';

import VueHotjar from 'vue-hotjar-next';

import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;

const messages = Object.assign(languages)
const localStorageLang  = localStorage.getItem("lang")

const i18n = createI18n({
  legacy: false,
  locale: localStorageLang || defaultLocale,
  fallbackLocale: 'en',
  messages
})

createApp(App)
  .use(i18n)
  .use(components)
  .use(mitt)
  .use(axios)
  .use(store)
  .use(router)
  .use(middleware)
  .use(VueHotjar, {
    id: process.env.VUE_APP_HOTJAR_ID || 2953676,
    isProduction: true,
    snippetVersion: 6
  })
  .use(PerfectScrollbar)
  .use(Tabs)
  .use(Row)
  .use(Col)
  .use(Space)
  .use(Badge)
  .use(Layout)
  .use(Typography)
  .use(Radio)
  .use(Checkbox)
  .use(Avatar)
  .use(Button)
  .use(Input)
  .use(Select)
  .use(Dropdown)
  .use(Menu)
  .use(Table)
  .use(Statistic)
  .use(Tooltip)
  .use(Spin)
  .use(Form)
  .use(Tabs)
  .use(Skeleton)
  .use(Switch)
  .use(Collapse)
  .use(Slider)
  .use(Modal)
  .use(DatePicker)
  .use(InputNumber)
  .mount('#app');
