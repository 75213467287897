<template>
  <router-link
    class="logotype"
    :to="{ name: 'Overview' }"
  >
    <img :src="logotype" alt="logotype" />
  </router-link>
</template>

<script>
import { defineComponent, computed } from "vue";
import { images } from "@/assets";
import useAuth from "@/composables/useAuth";

export default defineComponent({
  name: "HeaderLogotype",

  setup() {
    const auth = useAuth();
    const user = computed(() => auth.user());

    return {
      logotype: images.logotype,
      user,
    };
  },
});
</script>

<style lang="less" scoped>
.logotype {
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.7;
  }

  img {
    //mw_logo

    // padding-left: 1.7rem;
    // width: 15.0rem;
    // height: 2.6rem;

    //emd_logo
    padding-left: 1.7rem;
    width: 16.3rem;
    height: 3.2rem;
  }
}
</style>
